// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/index').default,
    "routes": [
      {
        "exact": true,
        "path": "/",
        "component": require('/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/pages/index').default
      },
      {
        "exact": true,
        "path": "/login",
        "component": require('/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/pages/login').default
      },
      {
        "exact": true,
        "path": "/platforms",
        "component": require('/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/pages/platforms').default
      },
      {
        "exact": true,
        "path": "/activityLog",
        "component": require('/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/pages/activityLog').default
      },
      {
        "exact": true,
        "path": "/403",
        "component": require('/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/pages/403').default
      },
      {
        "exact": true,
        "path": "/404",
        "component": require('/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/pages/login').default
      },
      {
        "exact": true,
        "path": "/:platform/login",
        "component": require('/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/pages/login').default
      },
      {
        "exact": false,
        "path": "/platform",
        "component": require('/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/pages/platform').default
      },
      {
        "component": require('/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/pages/login').default
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
