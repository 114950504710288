// @ts-nocheck
import { plugin } from './plugin';
import * as Plugin_0 from '/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/app.ts';
import * as Plugin_1 from '/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/.umi-production/plugin-dva/runtime.tsx';
import * as Plugin_2 from '../plugin-initial-state/runtime';
import * as Plugin_3 from '/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/.umi-production/plugin-locale/runtime.tsx';
import * as Plugin_4 from '../plugin-model/runtime';
import * as Plugin_5 from '@@/plugin-qiankun/masterRuntimePlugin';

  plugin.register({
    apply: Plugin_0,
    path: '/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/app.ts',
  });
  plugin.register({
    apply: Plugin_1,
    path: '/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/.umi-production/plugin-dva/runtime.tsx',
  });
  plugin.register({
    apply: Plugin_2,
    path: '../plugin-initial-state/runtime',
  });
  plugin.register({
    apply: Plugin_3,
    path: '/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/admin-panel/src/.umi-production/plugin-locale/runtime.tsx',
  });
  plugin.register({
    apply: Plugin_4,
    path: '../plugin-model/runtime',
  });
  plugin.register({
    apply: Plugin_5,
    path: '@@/plugin-qiankun/masterRuntimePlugin',
  });

export const __mfsu = 1;
